<template>
  <div class="content">
    <div class="nav">
      <div class="nav-item">{{ $t('home').mc }}</div>
      <div class="nav-item">{{ $t('home').zxj }}</div>
      <div class="nav-item">{{ $t('home').zdb }}</div>
    </div>
    <div class="box">
      <router-link
        class="item"
        :to="{ name: 'rapidly', params: { id: item.Id } }"
        v-for="item in productList"
        :key="item.Id"
      >
        <div class="name">
          <span>{{ item.name }}</span>
          <span class="usdt">/USDT</span>
        </div>
        <div class="price">
          {{ item.Price }}
        </div>
        <div
          class="range"
          :style="{
            background:
              item.percentage < 0
                ? basicConfig.FallColour
                : basicConfig.RiseColour
          }"
        >
          {{ item.percentage > 0 ? `+${item.percentage}` : item.percentage }}%
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['productList', 'basicConfig'])
  }
}
</script>

<style lang="less" scoped>
.content {
  padding: 0 10px;
  font-size: 20px;

  .nav {
    font-size: 20px;
    height: 30px;
    color: #8897ad;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .box {
    font-size: 20px;
    width: 100%;
    height: 555px;
    overflow-y: auto;
  }
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    font-size: 20px;
    font-weight: bold;
    color: #424344;
    border-bottom: 0.5px solid #ebeef5;
    .name {
      width: 120px;
      text-align: left;
    }
    .usdt {
      font-size: 16px;
      color: #8897ad;
    }
    .price {
      width: 100px;
      text-align: left;
    }
    .range {
      width: 120px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 400;
      background: #02c289;
      border-radius: 5px;
      // padding: 10px;
    }
    .up {
      background: #e27046;
      color: #424344 !important;
    }
  }
}
</style>
