<template>
  <div>
    <!-- 顶部 -->
    <!-- <top></top> -->
    <!-- 轮播 -->
    <div class="swiper">
      <div class="block">
        <el-carousel trigger="click" height="520px">
          <el-carousel-item v-for="(item, index) in rotationPics" :key="index">
            <img :src="item.ImgUrl" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="notice">
        <div class="inner">
          <img src="@/assets/new/notice.png" alt="" />
          <div @click="handleClick">
            {{ CommonNotice.Title }}
          </div>
        </div>
      </div>
      <div class="listbox">
        <div class="list">
          <ProductList></ProductList>
        </div>
      </div>
    </div>

    <!-- 涨幅榜 -->
    <div class="Rising">
      <p>
        <span> {{ $t('home').zfb }}</span>
      </p>
      <div class="box">
        <ProductColumnList></ProductColumnList>
      </div>
    </div>
    <!-- 全球的 -->
    <div class="global">
      <div class="bigbox">
        <p class="p1">{{ $t('pchome').global1 }}</p>
        <p class="p2">
          {{ $t('pchome').global2 }}
        </p>
        <div class="box">
          <div class="item">
            <img src="../../assets/pc/imte1.png" alt="" />
            <p class="p3">{{ $t('pchome').global3 }}</p>
            <p class="p4">
              {{ $t('pchome').global4 }}
            </p>
          </div>
          <div class="item">
            <img src="../../assets/pc/imte2.png" alt="" />
            <p class="p3">{{ $t('pchome').global5 }}</p>
            <p class="p4">
              {{ $t('pchome').global6 }}
            </p>
          </div>
          <div class="item">
            <img src="../../assets/pc/imte3.png" alt="" />
            <p class="p3">{{ $t('pchome').global7 }}</p>
            <p class="p4">
              {{ $t('pchome').global8 }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 随时随地 -->
    <div class="whever">
      <p class="p1">{{ $t('pchome').whever1 }}</p>
      <p class="p2">
        {{ $t('pchome').whever2 }}
      </p>
      <div class="box">
        <div class="item">
          <img src="../../assets/pc/下载.png" alt="" />
          iPhone
        </div>
        <div class="item">
          <img src="../../assets/pc/下载 (1).png" alt="" />
          Android
        </div>
        <div class="item">
          <img src="../../assets/pc/下载 (2).png" alt="" />
          Windows
        </div>
      </div>
    </div>
    <!-- 注册 -->
    <div class="register">
      <router-link to="/index/login">
        <p>
          {{ $t('pchome').register1 }}
          {{ this.basicConfig.CompanyNameEnglish }}
          {{ $t('pchome').register3 }}
        </p>
      </router-link>
      <router-link to="/index/register">
        <button>{{ $t('pchome').register2 }}</button>
      </router-link>
    </div>
    <!-- 底部 -->
    <!-- <bottom></bottom> -->
  </div>
</template>

<script>
// import top from '../../components_pc/top.vue'
// import bottom from '../../components_pc/bottom.vue'
import ProductList from './component/ProductList.vue'
import ProductColumnList from './component/ProductColumnList.vue'
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
import { IsreadSysNotice, showName } from '@/utils/validate'
import { EventBus } from '@/utils/event-bus'
export default {
  name: 'CurrencyMobileNewIndex',
  components: {
    // top,
    // bottom,
    ProductList,
    ProductColumnList
  },

  data() {
    return { rotationPics: [], CommonNotice: {} }
  },
  computed: {
    ...mapGetters(['connection', 'basicConfig', 'lang'])
  },
  watch: {
    lang(val) {
      const CommonNotice = this.basicConfig.CommonNotice
      if (CommonNotice && CommonNotice.length) {
        CommonNotice.some((item) => {
          if (item.LanguageType === showName(val)) {
            this.CommonNotice = item
            return true
          }
        })
      }
    }
  },
  mounted() {
    // console.log(this.basicConfig.LongLogo)
    // console.log(this.basicConfig.CompanyNameEnglish)
    this.getrotationPicslist()
    EventBus.$on('getBanner', () => {
      this.getrotationPicslist()
    })
    const LanguageSelect = this.basicConfig.LanguageSelect.join(',')
    if (LanguageSelect.indexOf('Chinese') === -1) {
      this.actions.splice(1, 1)
    }
    if (LanguageSelect.indexOf('English') === -1) {
      this.actions.splice(0, 1)
    }
    this.getLang(localStorage.getItem('language'))
    const lang = showName(this.lang)
    this.$nextTick(() => {
      setTimeout(() => {
        let PopupNotices = this.basicConfig.PopupNotices
        if (PopupNotices && IsreadSysNotice()) {
          PopupNotices.some((item) => {
            if (item.LanguageType === lang && item.Content) {
              this.$dialog.alert({
                confirmButtonText: this.$t('public').sure,
                title: item.Title,
                message: item.Content
              })
              return true
            }
          })
        }
      }, 500)

      const CommonNotice = this.basicConfig.CommonNotice
      if (CommonNotice && CommonNotice.length) {
        CommonNotice.some((item) => {
          if (item.LanguageType === lang) {
            this.CommonNotice = item
            return true
          }
        })
      }
    })
  },
  destroy() {
    EventBus.$off('getBanner')
  },
  methods: {
    async getLang(lang) {
      let LanguageType = ''
      let PortType = 'APP'
      if (lang === 'zh') {
        LanguageType = 'Chinese'
      } else if (lang === 'en') {
        LanguageType = 'English'
      } else if (lang === 'ty') {
        LanguageType = 'Thai'
      } else if (lang === 'ft') {
        LanguageType = 'Traditional'
      } else if (lang === 'jp') {
        LanguageType = 'Japanese'
      } else if (lang === 'hy') {
        LanguageType = 'Korea'
      } else if (lang === 'yn') {
        LanguageType = 'Indonesia'
      }
      this.rotationPics = await userApi.rotationPics({ LanguageType, PortType })
    },
    handleClick() {
      this.$router.push({
        name: 'Detail',
        query: {
          title: this.CommonNotice.Title,
          info: this.CommonNotice.Content
        }
      })
    },
    async getrotationPicslist() {
      let LanguageType = ''
      let PortType = 'PC'
      // console.log(this.$i18n.locale)
      // if (this.$i18n.locale == 'zh') {
      //   LanguageType = 'Chinese'
      // } else if (this.$i18n.locale == 'en') {
      //   LanguageType = 'English'
      // }
      if (this.$i18n.locale === 'zh') {
        LanguageType = 'Chinese'
      } else if (this.$i18n.locale === 'en') {
        LanguageType = 'English'
      } else if (this.$i18n.locale === 'ty') {
        LanguageType = 'Thai'
      } else if (this.$i18n.locale === 'jp') {
        LanguageType = 'Japanese'
      } else if (this.$i18n.locale === 'hy') {
        LanguageType = 'Korea'
      } else if (this.$i18n.locale === 'yn') {
        LanguageType = 'Indonesia'
      } else {
        LanguageType = 'Traditional'
      }
      this.rotationPics = await userApi.rotationPics({ LanguageType, PortType })
      // console.log(this.rotationPics)
    }
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #424344;
}
.notice {
  background: #17181e;
  height: 60px;
  display: flex;
  align-items: center;
  // padding: 0 10px;
  font-size: 12px;

  .inner {
    width: 60%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    background: #17181e;
    img {
      width: 40px;
      height: 40px;
      margin-right: 20px;
      background: #17181e;
    }
  }
  div {
    background-color: #17181e;
    font-size: 18px;
    color: #fff;
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.swiper {
  // width: 80%;
  // margin: 0 auto;
  .block {
    // width: 80%;

    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .listbox {
    background-color: #1e1e1e;
    .list {
      width: 80%;
      margin: 0 auto;
    }
  }
}
.Rising {
  width: 80%;
  margin: 20px auto;
  text-align: center;
  p {
    padding: 12px;
    margin-top: 34px;
    margin-bottom: 30px;
    span {
      font-size: 20px;
      padding: 10px;
      background-color: #eef0f2;
      border-radius: 10px;
    }
  }
}
.global {
  margin-top: 60px;
  padding-bottom: 60px;
  background-color: #f8f8f8;
  text-align: center;
  .bigbox {
    width: 80%;
    margin: 0 auto;
    background-color: #f8f8f8;
    // p {
    //   background-color: #f8f8f8;
    // }
    .p1 {
      font-size: 35px;
      font-weight: 600;
      color: #1e1e1e;
      margin-bottom: 20px;
      background-color: #f8f8f8;
    }
    .p2 {
      font-size: 14px;
      background-color: #f8f8f8;
      margin-bottom: 60px;
      text-align: center;
    }
    .box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item {
        width: 30%;

        .p3 {
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 10px;
          color: #000;
        }
        .p4 {
          font-size: 14px;
          font-family: PingFang SC;
          line-height: 20px;
        }
      }
    }
  }
}
.whever {
  text-align: center;
  width: 100%;
  padding: 60px 0;
  box-sizing: border-box;
  .p1 {
    color: #1e1e1e;
    font-size: 35px;
    font-weight: 900;
  }
  .p2 {
    color: #1e1e1e;
    font-size: 14px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 40px;
    margin-top: 20px;
  }
  .box {
    width: 30%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item {
      width: 112px;
      height: 36px;
      border: 1px solid #1e1e1e;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-around;
      cursor: pointer;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border: 0.5px solid #1e1e1e;
      border-radius: 3px;
      // padding: 2px 5px;
      // width: 30px;
      // height: 10px;
      img {
        width: 18px;
        height: 18px;
        display: block;
      }
    }
  }
}
.register {
  width: 100%;
  height: 370px;
  background-image: url(../../assets/pc/register.png);
  background-size: 100% 100%;
  padding: 25px;
  padding-top: 90px;
  box-sizing: border-box;

  text-align: center;
  p {
    text-align: center;
    background-color: transparent;
    color: #ffffff;
    font-size: 30px;
    margin-bottom: 60px;
  }
  button {
    background-color: #3d67fa;
    text-align: center;
    font-weight: bolder;
    color: #fff;
    font-size: 20px;
    width: 380px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border-radius: 6px;
    margin: 0 auto;
    display: block;
  }
}
</style>
